import { render, staticRenderFns } from "./BodyFB.vue?vue&type=template&id=515809ac&scoped=true"
import script from "./BodyFB.vue?vue&type=script&lang=js"
export * from "./BodyFB.vue?vue&type=script&lang=js"
import style0 from "./BodyFB.vue?vue&type=style&index=0&id=515809ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "515809ac",
  null
  
)

export default component.exports